import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { AdministracionCarteraApiService } from '../services/administracion-cartera-api.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { BagResponse } from '../models/bag';
import { BatchConfigList, BatchConfigListItem, BatchTemplate, Destination, Frequency, Mapper, OrgBatchTemplate } from '../models/batch';
import { QueryDetailModalComponent } from '../components/query-detail-modal/query-detail-modal.component';

@Component({
  selector: 'app-admin-cartera-process-panel',
  templateUrl: './admin-cartera-process-panel.component.html',
  styleUrls: ['./admin-cartera-process-panel.component.scss']
})
export class AdminCarteraProcessPanelComponent {

  private access: string[] = [ESystemAccess.ADMINISTRACION_CARTERA_ADMIN, ESystemProfileList.WEB];
  
  id: string | null = null;
  detail: BagResponse | null = null;
  batchConfigs: BatchConfigListItem[] = []

  currentPage = 1;
  itemsPerPage = 5; // Define how many items per page
  totalPages = 1;
  paginatedProcesses: BatchConfigListItem[] = [];

  modelos: OrgBatchTemplate[] = []
  modeloSelected: OrgBatchTemplate | undefined = undefined

  frecuencias: Frequency[] = []
  frecuenciaSelected: Frequency | undefined = undefined

  queries: Mapper[] = []
  querySelected: Mapper | undefined = undefined

  destinos: Destination[] = [{type: "Bucket Interno"}, {type: "Bucket Externo"}]
  destinoSelected: Destination | undefined = undefined

  mappersContract: Mapper[] = []

  isAsigning = true;

  constructor(
    private _spinner: NgxSpinnerService,
    private _alertsService: AlertService,
    private _apiService: AdministracionCarteraApiService,
    // private location: Location,
    public dialog: MatDialog,
    private _router: Router,
    private _session: SessionService,
    private _route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    if(!this.checkAccess()) this._router.navigate(['/request-access']);

    this._route.paramMap.subscribe(params => {
      this.id = params.get('id'); // Get the 'id' from the route
      if(this.id) this.getBagData(this.id);
    });
  }

  getBagData(id: string) {
    this._spinner.show();
    forkJoin({
      detail: this._apiService.getBagDetail(id),
      processes: this._apiService.getBagProcesses(id),
      batchTemplates: this._apiService.listOrgBatchTemplates(),
      mappers: this._apiService.listOrgMappers(),
    }).subscribe(results => {
      this.detail = results.detail as BagResponse;
      const processes = results.processes as BatchConfigList;
      
      if(processes.batchConfigs){
        this.batchConfigs = processes.batchConfigs;
      } else {
        // this._alertsService.error("Error al obtener configuraciones de cartera");
      }
      
      if(results.batchTemplates.batchTemplates) {
        this.modelos = results.batchTemplates.batchTemplates as OrgBatchTemplate[];
      } else {
        this._alertsService.error("Error al obtener modelos de cartera");
      }

      if(results.mappers?.mappers) {
        this.mappersContract = results.mappers.mappers;
      } else {
        this._alertsService.error("Error al obtener mappers de cartera");
      }

      this._spinner.hide();
      this.setBatchTable();

    },
    (error) => {
      this._alertsService.error("Error al obtener datos de cartera");
      this._spinner.hide();
    });
  }

  checkAccess() {
    const userAccess = this._session.getUserAccess();
    const getUserProfileList = this._session.getUserProfileList();

    if (this._session.getUserAccess().includes(this.access[0]) && this._session.getUserProfileList().includes(this.access[1])) {
      return true;
    } else {
      return false;
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.setBatchTable();
    }
  }

  setBatchTable() {
    this.totalPages = Math.ceil(this.batchConfigs.length / this.itemsPerPage);
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedProcesses = this.batchConfigs.slice(startIndex, endIndex);  
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.setBatchTable();
    }
  }

  onEdit() {
    this._router.navigate(['/administracion-cartera-editar', this.id]);
  }

  back() {
    this._router.navigate(['/administracion-cartera'])
  }

  setFrequencies() {
    if(this.modeloSelected?.batchTemplate.availableFrequencies) {
      this.frecuencias = this.modeloSelected?.batchTemplate.availableFrequencies
    }
    else {
      this._alertsService.error("Error al cargar las frecuencias")
    }
    if(this.modeloSelected?.batchTemplate.mappers) {
      this.queries = this.mapIdWithNames(this.modeloSelected?.batchTemplate.mappers, this.mappersContract);
      console.log(this.queries)
    }
    else {
      this._alertsService.error("Error al cargar las queries")
    }
  }

  asignConfig() {
    this.isAsigning = true;

    this._apiService.asignBatchConfig(
      this.id!, 
      this.frecuenciaSelected, 
      this.modeloSelected, 
      this.querySelected, 
      this.destinoSelected)
      .subscribe(
        (response) => {
          this._alertsService.success("Configuración asignada correctamente");
          this.isAsigning = false;
          this.getBagData(this.id!);
        },
        (error) => {
          this._alertsService.error("Error al asignar configuración");
        }
    );
  }

  removeConfig(process: BatchConfigListItem) {
    this._spinner.show();
    this._apiService.removeBatchConfig(process.id, this.id!).subscribe(
      (response) => {
        this._alertsService.success("Configuración eliminada correctamente");
        this._spinner.hide()
        this.getBagData(this.id!);
      },
      (error) => {
        this._alertsService.error("Error al eliminar configuración");
        this._spinner.hide()
      }
    );
  }

  restartStepper() {
    window.location.reload();
  }

  mapIdWithNames(idsArray: Mapper[], namesArray: Mapper[]): Mapper[] {
    return idsArray
    .map((idObj: Mapper) => {
      // Find the object in namesArray that matches the id
      const matchingNameObj = namesArray.find(nameObj => nameObj.id === idObj.id);
      
      // Return a new object with id and name, or undefined if no match is found
      return matchingNameObj ? { id: idObj.id, name: matchingNameObj.name } as Mapper : undefined;
    })
    .filter((obj): obj is Mapper => obj !== undefined);
  }

  openQueryDetail(queryId: string) {
    const dialogRef = this.dialog.open(QueryDetailModalComponent, {
      data: {
          id: queryId
      }
    });
  }
}
