import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { AdministracionCarteraApiService } from '../services/administracion-cartera-api.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { BagResponse } from '../models/bag';
import { BatchConfigList, BatchConfigListItem } from '../models/batch';

@Component({
  selector: 'app-admin-cartera-detail',
  templateUrl: './admin-cartera-detail.component.html',
  styleUrls: ['./admin-cartera-detail.component.scss']
})
export class AdminCarteraDetailComponent {
  
  private access: string[] = [ESystemAccess.ADMINISTRACION_CARTERA, ESystemProfileList.WEB];
  
  id: string | null = null;
  detail: BagResponse | null = null;
  batchConfigs: BatchConfigListItem[] = []

  currentPage = 1;
  itemsPerPage = 5; // Define how many items per page
  totalPages = 1;
  paginatedProcesses: BatchConfigListItem[] = [];
  isAdmin: boolean = false;
  adminAccess: string = ESystemAccess.ADMINISTRACION_CARTERA_ADMIN;

  constructor(
    private _sessionService: SessionService,
    private _formBuilder: FormBuilder,
    private _spinner: NgxSpinnerService,
    private _alertsService: AlertService,
    private _apiService: AdministracionCarteraApiService,
    // private location: Location,
    public dialog: MatDialog,
    private _router: Router,
    private _session: SessionService,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if(!this.checkAccess()) this._router.navigate(['/request-access']);

    this._route.paramMap.subscribe(params => {
      this.id = params.get('id'); // Get the 'id' from the route
      if(this.id) this.getBagData(this.id);
    });
  }

  getBagData(id: string) {
    this._spinner.show();
    forkJoin({
      detail: this._apiService.getBagDetail(id),
      processes: this._apiService.getBagProcesses(id)
    }).subscribe(results => {
      this.detail = results.detail as BagResponse;
      const processes = results.processes as BatchConfigList;
      
      if(processes.batchConfigs){
        this.batchConfigs = processes.batchConfigs;
      }
      
      this._spinner.hide();
      this.setBatchTable();
    },
    (error) => {
      this._alertsService.error("Error al obtener datos de cartera");
      this._spinner.hide();
    });
  }

  setBatchTable() {
    this.totalPages = Math.ceil(this.batchConfigs.length / this.itemsPerPage);
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedProcesses = this.batchConfigs.slice(startIndex, endIndex);  
  }

  checkAccess() {
    const userAccess = this._session.getUserAccess();
    const getUserProfileList = this._session.getUserProfileList();

    if (this._session.getUserAccess().includes(this.adminAccess)) {
      this.isAdmin = true;
    }

    if (this._session.getUserAccess().includes(this.access[0]) && this._session.getUserProfileList().includes(this.access[1])) {
      return true;
    } else {
      return false;
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.setBatchTable();
    }
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.setBatchTable();
    }
  }

  onEdit() {
    this._router.navigate(['/administracion-cartera-editar', this.id]);
  }

  onAdmin() {
    this._router.navigate(['/administracion-cartera-procesos', this.id]);
  }

  onExecutions() {
    this._router.navigate(['/administracion-cartera-ejecuciones', this.id]);
  }

  back() {
    this._router.navigate(['/administracion-cartera'])
  }
}
