import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { SessionService } from 'src/app/shared/services/session.service';
import { Location } from '@angular/common';
import { AdministracionCarteraApiService } from '../services/administracion-cartera-api.service';
import { Bag } from '../models/bag';
import { Router } from '@angular/router';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';

@Component({
  selector: 'app-admin-cartera-create',
  templateUrl: './admin-cartera-create.component.html',
  styleUrls: ['./admin-cartera-create.component.scss']
})
export class AdminCarteraCreateComponent {
 
  public hasUserAccess = true;

  private access: string[] = [ESystemAccess.ADMINISTRACION_CARTERA, ESystemProfileList.WEB];


  public formularioArchivo: FormGroup;
  public fileName: boolean = false;
  private tempFile!: any;
 
  constructor(
    private _sessionService: SessionService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private alertsService: AlertService,
    private _apiService: AdministracionCarteraApiService,
    private location: Location,
    public dialog: MatDialog,
    private _router: Router,
    private _session: SessionService,
  ) { 
    this.formularioArchivo = this.formBuilder.group({
      file: [null]
    })
  }

  ngOnInit(): void {
    if(!this.checkAccess()) this._router.navigate(['/request-access']);
  }

  checkAccess() {
    const userAccess = this._session.getUserAccess();
    const getUserProfileList = this._session.getUserProfileList();

    if (this._session.getUserAccess().includes(this.access[0]) && this._session.getUserProfileList().includes(this.access[1])) {
      return true;
    } else {
      return false;
    }
  }

  obtenerLista(): void {
    this.initFormArchivo()
  }

  descargarArchivo(idTransaccion: string, base64String: string): void {
    const button: any = document.getElementById(idTransaccion);
    if(button){
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      button.href = url;
      button.download = idTransaccion + '.csv';
      button.click();
      URL.revokeObjectURL(url);
    }
  }

  // Consulta para descargar archivo
  obtenerArchivo(elemento: any): void {
    const idTransaccion = elemento?.IDTransaccion;
    if(idTransaccion) {
      this.spinner.show();
      // this.credencialesService.obtenerArchivoCargaMasiva(idTransaccion).subscribe(
      //   (data: any) => {
      //     if(data?.ArchivoResultado){
      //       this.descargarArchivo(idTransaccion, data?.ArchivoResultado);
      //     } else {
      //       this.alertsService.error('Ha ocurrido un error intentar rescatar el archivo.');
      //     }
      //     this.spinner.hide();
      //   },
      //   ({ error }) => {
      //     this.alertsService.error(error.message || 'Ha ocurrido un error intentar descargar el archivo.');
      //     this.spinner.hide();
      // });

    }
  }

  initFormArchivo(): void {
    this.formularioArchivo = this.formBuilder.group({
      file: [null]
    })
  }

  handleUpload(event: any) {
    const file = event.target.files[0];
    if(file.size <= 1000000){
      // falta validacion tipo de dato
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.tempFile = reader?.result?.toString().split(',')[1];
      };
    } else {
      this.alertsService.error('El tamaño maximo del archivo debe ser de 1mb');
      this.tempFile = {};
    }
  }

  submitArchivo() {
    // this.spinner.show();
    if(typeof(this.tempFile) === 'string' && this.tempFile?.length > 0){
      const idOrganizacion = this._sessionService.getOrganization();
      const idUsuario = this._sessionService.getUserInfo().username;
      const bag: Bag = {
        name: 'Cartera ' + idOrganizacion + ' ' + new Date().getTime(),
        description: idOrganizacion + ' ' + idUsuario,
        base64Content: this.tempFile
      }
      
      this.spinner.show();
      this._apiService.createBag(bag).subscribe(
        (res) => {
          // Obtener id y redireccionar al detalle
          console.log(res);
          if(!res.bagId) {
            this.alertsService.error("Error al crear cartera")
          } else {
            this._router.navigate(['/administracion-cartera-detalle', res.bagId])
          }
          this.spinner.hide()
        },
        (error) => {
          console.log(error);
          this.spinner.hide()
          // Alert service
          if(error.error.message) {
            this.alertsService.error("Error al crear cartera")
          }
        }
      )
    } else {
      this.alertsService.error('Ocurrio un error con el archivo.');
      this.spinner.hide();
    }
  }

  actualizarTabla(): void {
    this.obtenerLista();
  }

  back() {
    this.location.back();
  }
}
