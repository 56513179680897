import { Component } from '@angular/core';
import { BagListItem } from '../models/bag';
import { AdministracionCarteraApiService } from '../services/administracion-cartera-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert/alert.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/shared/services/session.service';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-cartera-list',
  templateUrl: './admin-cartera-list.component.html',
  styleUrls: ['./admin-cartera-list.component.scss']
})
export class AdminCarteraListComponent {
  public carteras: BagListItem[] = [];

  // TODO: Cambiar por admin de cartera
  private access: string[] = [ESystemAccess.ADMINISTRACION_CARTERA, ESystemProfileList.WEB];
  isAdmin: boolean = false;
  adminAccess: string = ESystemAccess.ADMINISTRACION_CARTERA_ADMIN;

  filteredCarteras = [...this.carteras]; // Array to hold filtered carteras
  searchTerm = '';

  // Pagination settings
  itemsPerPage = 10;
  currentPage = 1;
  totalPages = 1;
  pagedCarteras: BagListItem[] = [];
  pageSizeOptions = [5, 10, 20];

  constructor(
    private _apiService: AdministracionCarteraApiService,
    private _spinner: NgxSpinnerService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private _session: SessionService,  
    private _router: Router
  ) {
    
  }

  ngOnInit() {
    if(!this.checkAccess()) this._router.navigate(['/request-access']);

    this._spinner.show();
    this._apiService.getBagList().subscribe(
      (response: any) => {
        // this.carteras = response.data;
        this._spinner.hide();
        if(!response.bags){
          this.alertService.error('No se encontraron carteras')
        } else {
          this.carteras = response.bags;
          this.filteredCarteras = [...this.carteras];
          this.calculateTotalPages();
          this.updatePagedCarteras();
        }
      },
      (error) => {
        console.error(error);
        this.alertService.error('Error al obtener carteras')
        this._spinner.hide();
      }
    )

    this.calculateTotalPages();
    this.updatePagedCarteras();
  }

  checkAccess() {
    const userAccess = this._session.getUserAccess();
    const getUserProfileList = this._session.getUserProfileList();

    if (this._session.getUserAccess().includes(this.adminAccess)) {
      this.isAdmin = true;
    }

    if (this._session.getUserAccess().includes(this.access[0]) && this._session.getUserProfileList().includes(this.access[1])) {
      return true;
    } else {
      return false;
    }
  }

  // Function to calculate the total pages based on filtered carteras
  calculateTotalPages() {
    this.totalPages = Math.ceil(this.filteredCarteras.length / this.itemsPerPage);
  }

  // Function to update the current page of carteras
  updatePagedCarteras() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.pagedCarteras = this.filteredCarteras.slice(startIndex, endIndex);
  }

  // Go to the previous page
  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePagedCarteras();
    }
  }

  // Go to the next page
  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updatePagedCarteras();
    }
  }

  // Change the number of items per page
  changeItemsPerPage(event: any) {
    this.itemsPerPage = +event.target.value;
    this.currentPage = 1; // Reset to the first page when changing items per page
    this.calculateTotalPages();
    this.updatePagedCarteras();
  }

  // Remove a cartera from the list
  removeCartera(id: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
          title: 'Confirmación',
          message: `¿Desea eliminar la cartera ${id}?`,
          textBoton: ['Si', 'No'],
          textTimer: environment.inactivity.countdown + 1
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (!resp) {
            
          } else {
            this._apiService.deleteBag(id).subscribe(
              (response: any) => {
                this.alertService.success('Cartera eliminada correctamente');
                this.ngOnInit();
              },
              (error: any) => {
                console.error(error);
                this.alertService.error('Error al eliminar cartera')
              }
            )
          }
    });
  }

  // Search Carteras by ID or Name
  searchCarteras() {
    this.filteredCarteras = this.carteras.filter(cartera => 
      cartera.id.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      cartera.name.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
    this.currentPage = 1; // Reset to the first page after search
    this.calculateTotalPages();
    this.updatePagedCarteras();
  }

  openBag(id: string) {
    this._router.navigate(['/administracion-cartera-detalle', id]);
  }

  createBag() {
    this._router.navigate(['/administracion-cartera-crear']);
  }

}
