<div id="page-wrapper" appUserActive (calltMethod)="authenticator.authStatus === 'authenticated' ? userActive() : null"><!--(calltMethod)="userActive()"-->
    <ngx-spinner bdColor="rgb(0,0,0, 0.2)" size="large" color="#89e8ff" type="line-spin-clockwise-fade"
        [fullScreen]="true">
        <p style="color: white"> Cargando... </p>
    </ngx-spinner>
    <ng-container *ngIf="authenticator.authStatus === 'authenticated'; else elseLogin">
        <div id="page-container" class="sidebar-partial sidebar-visible-lg sidebar-no-animations">
            <!-- Main Sidebar -->
            <app-side-menu [ngClass]="{'d-none': !showContent}"></app-side-menu>
            <!-- END Main Sidebar -->
    
            <!-- Main Container -->
            <div id="main-container" [ngClass]="{'d-none': !showContent}">
                <header class="navbar navbar-default justify-content-start">
                    <ul class="nav navbar-nav-custom">
                        <li>
                            <div class="menuToggle">
                            <a href="javascript:void(0)" style="color: #000" onclick="App.sidebar('toggle-sidebar');this.blur();">
                                <i class="fa fa-bars fa-fw"></i>
                            </a>
                            <span>{{ruta}}</span> <span id="span-menutoggle"> </span>
                        </div>
                        </li>
                    </ul>
                </header>
    
                <!-- BORRAR -->
                <!-- <button class="btn" (click)="reload()">TEST BUTTON</button> -->
    
                <!-- Page content -->
                <div id="page-content">
                    <alert></alert>
                    <router-outlet></router-outlet>
                </div>
                <!-- END Page Content -->
    
                <!-- Footer -->
                <footer class="clearfix">
                    <div class="pull-right">
                        Hecho con <i class="fa fa-heart text-danger"></i> por <a href="https://www.informax.cl"
                            target="_blank">Informax</a>
                    </div>
                    <div class="pull-left">
                        Informax &copy; 2021 - version 0.7.78
                    </div>
                </footer>
                <!-- END Footer -->
            </div>
            
            <!-- END Main Container -->
        </div>
        <!-- END Page Container -->
    </ng-container>
    <ng-template #elseLogin>
        <alert></alert>
        <app-login></app-login>
    </ng-template>
</div>
<!-- END Page Wrapper -->

<!-- Scroll to top link, initialized in js/app.js - scrollToTop() -->
<a href="#" id="to-top"><i class="fa fa-angle-double-up"></i></a>

<!-- END User Settings -->
<!-- Load and execute javascript code used only in this page -->
<script src="assets/js/pages/index.js"></script>
<script>$(function () { Index.init(); });</script>