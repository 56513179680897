import { Component } from '@angular/core';
import { BatchConfigList, BatchConfigListItem, BatchProcess, mergedExecutionItem, OrgBatchTemplate } from '../models/batch'
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { AdministracionCarteraApiService } from '../services/administracion-cartera-api.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { BagResponse } from '../models/bag';
import { ExecDetailModalComponent } from '../components/exec-detail-modal/exec-detail-modal.component';

@Component({
  selector: 'app-admin-cartera-execution-panel',
  templateUrl: './admin-cartera-execution-panel.component.html',
  styleUrls: ['./admin-cartera-execution-panel.component.scss']
})
export class AdminCarteraExecutionPanelComponent {
  private access: string[] = [ESystemAccess.ADMINISTRACION_CARTERA, ESystemProfileList.WEB];
  
  id: string | null = null;
  detail: BagResponse | null = null;
  modelos: OrgBatchTemplate[] = []; // TODO: levantar del back
  selectedModel = '';
  fechaInicio: string = '';
  fechaFin: string = '';

  // Mock data
  procesos: mergedExecutionItem[] = [];

  currentPage = 1;
  pageSize = 3;
  paginatedData: mergedExecutionItem[] = [];
  totalPages = 1;

  batchConfigs: BatchConfigListItem[] = []

  filterParams: any = {
    batchTemplateTag: undefined,
    dateFrom: undefined,
    dateTo: undefined,
    bagId: undefined,
  }

  constructor(
    private _spinner: NgxSpinnerService,
    private _alertsService: AlertService,
    private _apiService: AdministracionCarteraApiService,
    // private location: Location,
    public dialog: MatDialog,
    private _router: Router,
    private _session: SessionService,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.paginate();

    if(!this.checkAccess()) this._router.navigate(['/request-access']);

    this._route.paramMap.subscribe(params => {
      this.id = params.get('id'); // Get the 'id' from the route
      if(this.id) {
        this.getBagData(this.id);
        this.filterParams.bagId = this.id;
      } 
    });
  }

  checkAccess() {
    const userAccess = this._session.getUserAccess();
    const getUserProfileList = this._session.getUserProfileList();

    if (this._session.getUserAccess().includes(this.access[0]) && this._session.getUserProfileList().includes(this.access[1])) {
      return true;
    } else {
      return false;
    }
  }

  getBagData(id: string) {
    this._spinner.show();
    forkJoin({
      detail: this._apiService.getBagDetail(id),
      processes: this._apiService.getBagProcesses(id),
      batchTemplates: this._apiService.listOrgBatchTemplates(),
    }).subscribe(results => {
      this.detail = results.detail as BagResponse;      
      this._spinner.hide();

      const processes = results.processes as BatchConfigList;
      
      if(processes.batchConfigs){
        this.batchConfigs = processes.batchConfigs;
      }

      if(results.batchTemplates.batchTemplates) {
        this.modelos = results.batchTemplates.batchTemplates as OrgBatchTemplate[];
      } else {
        this._alertsService.error("Error al obtener modelos de cartera");
      }
    },
    (error) => {
      this._alertsService.error("Error al obtener datos de cartera");
      this._spinner.hide();
    });
  }

  onModelChange(selectedModel: string): void {
    this.filterParams.batchTemplateTag = selectedModel;    
  }

  onDateChange(field: string, selectedDate: string): void {
    if (field === 'fechaInicio') {
      this.filterParams.dateFrom = this.formatDateToISO(field, selectedDate);
    } else if (field === 'fechaFin') {
      this.filterParams.dateTo = this.formatDateToISO(field, selectedDate);
    }
  }

  formatDateToISO(field: string, dateString: string): string {
    // Create a new Date object from the YYYY-MM-DD string
    const date = new Date(dateString);
  
    // Set the time (you can adjust this part if you want specific times)
    if (field === 'fechaFin') {
      date.setHours(23, 59, 59);  // Set hours, minutes, seconds as per your need
      // console.log('Fecha Fin changed:', selectedDate);
      // Add your logic for Fecha Fin here
    }
  
    // Format the date back to YYYY-MM-DDTHH:mm:ss
    const formattedDate = date.toISOString().slice(0, 19);  // Taking only the date and time part
  
    return formattedDate;
  }

  // Function to handle searching/filtering
  buscar() {
    this._spinner.show();
    // Logic to filter the data by selectedModel, fechaInicio, fechaFin, etc.
    // After filtering, paginate the data again
    this._apiService.listBatchExecutions(this.filterParams).subscribe((data) => {
      if(data.message) {
        this._alertsService.error("No se han encontrado ejecuciones para esta cartera"); 
        this._spinner.hide();
        return;
      } 
      const executions = data as BatchProcess[];
      this.procesos = this.mergeArrays(this.batchConfigs, executions);
      console.log(this.procesos);
      this._spinner.hide();
      this.paginate();
    },
    (error) => {
      this._alertsService.error("Error al obtener datos de cartera");
      this._spinner.hide();
    })
  }

  // Pagination logic
  paginate() {
    this.totalPages = Math.ceil(this.procesos.length / this.pageSize);
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedData = this.procesos.slice(startIndex, endIndex);
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.paginate();
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.paginate();
    }
  }

  goBack() {
    // Logic for navigating back to the previous page
    this._router.navigate(['/administracion-cartera-detalle', this.id]);
  }

  viewExecDetail(id: string, configId: string) {
    const dialogRef = this.dialog.open(ExecDetailModalComponent, {
      data: {
          id: id,
          configId: configId
      }
    });
  }

  private mergeArrays(batchArray: BatchConfigListItem[], executionArray: BatchProcess[]) {
    return executionArray.map(execution => {
      const matchingBatch = batchArray.find(batch => batch.id === execution.batchConfigId);
      
      if (matchingBatch) {
        // Merge the batch and execution when a match is found
        return {
          ...matchingBatch,   // Spread attributes from BatchItem
          ...execution        // Spread attributes from ExecutionItem
        } as (BatchConfigListItem & BatchProcess);  // Cast the merged object to the merged interface
      }
      
      // If no matching batch, return the execution as it is
      return execution as (BatchConfigListItem & BatchProcess);
    });
  }
}
